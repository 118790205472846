import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import "gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css"

export const wrapPageElement = ({ element, props }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LcWb-MZAAAAACi8XYYOsNUfgS06GER0kFKG-Zir">
            {element}
        </GoogleReCaptchaProvider>
    )
}
