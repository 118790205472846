exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-press-release-20-03-26-js": () => import("./../../../src/pages/press-release/20-03-26.js" /* webpackChunkName: "component---src-pages-press-release-20-03-26-js" */),
  "component---src-pages-press-release-20-05-05-js": () => import("./../../../src/pages/press-release/20-05-05.js" /* webpackChunkName: "component---src-pages-press-release-20-05-05-js" */),
  "component---src-pages-press-release-21-05-24-js": () => import("./../../../src/pages/press-release/21-05-24.js" /* webpackChunkName: "component---src-pages-press-release-21-05-24-js" */),
  "component---src-pages-press-release-21-06-09-js": () => import("./../../../src/pages/press-release/21-06-09.js" /* webpackChunkName: "component---src-pages-press-release-21-06-09-js" */),
  "component---src-pages-press-release-23-10-26-js": () => import("./../../../src/pages/press-release/23-10-26.js" /* webpackChunkName: "component---src-pages-press-release-23-10-26-js" */),
  "component---src-pages-press-release-24-06-28-js": () => import("./../../../src/pages/press-release/24-06-28.js" /* webpackChunkName: "component---src-pages-press-release-24-06-28-js" */),
  "component---src-pages-press-release-js": () => import("./../../../src/pages/press-release.js" /* webpackChunkName: "component---src-pages-press-release-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-product-lily-playground-books-js": () => import("./../../../src/pages/product/lily-playground-books.js" /* webpackChunkName: "component---src-pages-product-lily-playground-books-js" */),
  "component---src-pages-product-lily-playground-books-setup-ipad-js": () => import("./../../../src/pages/product/lily-playground-books/setup-ipad.js" /* webpackChunkName: "component---src-pages-product-lily-playground-books-setup-ipad-js" */),
  "component---src-pages-product-lily-playground-books-setup-mac-js": () => import("./../../../src/pages/product/lily-playground-books/setup-mac.js" /* webpackChunkName: "component---src-pages-product-lily-playground-books-setup-mac-js" */),
  "component---src-pages-product-lily-swift-js": () => import("./../../../src/pages/product/lily-swift.js" /* webpackChunkName: "component---src-pages-product-lily-swift-js" */),
  "component---src-pages-product-sf-cell-js": () => import("./../../../src/pages/product/sf-cell.js" /* webpackChunkName: "component---src-pages-product-sf-cell-js" */),
  "component---src-pages-product-sf-cell-performance-js": () => import("./../../../src/pages/product/sf-cell/performance.js" /* webpackChunkName: "component---src-pages-product-sf-cell-performance-js" */),
  "component---src-pages-site-agreement-js": () => import("./../../../src/pages/site-agreement.js" /* webpackChunkName: "component---src-pages-site-agreement-js" */),
  "component---src-pages-vision-js": () => import("./../../../src/pages/vision.js" /* webpackChunkName: "component---src-pages-vision-js" */)
}

